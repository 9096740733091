import React from "react";
import { string, number } from "prop-types";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import { viewports } from "../../../style-vars";

const ProductSlide = ({
  title,
  subtext,
  imageName,
  slideIndex,
  numberOfSlides,
}) => {
  const slide = `0${slideIndex + 1}`;
  const slides = `0${numberOfSlides}`;

  return (
    <div
      className={`relative
    flex flex-col xl:flex-row
    xl:align-middle xl:justify-between
    xl:gap-x-12 xl:gap-x-24
    gap-y-20
    `}
    >
      <div
        className={`
        product-slide__image-wrapper
        w-full flex flex-row items-center
        xl:max-w-60-percent lg:pl-10 
      `}
      >
        <AppetizeImage
          className="product-slide__image w-full h-auto"
          imageName={imageName}
        />
      </div>

      <div>
        {slideIndex !== null && numberOfSlides !== null && (
          <div className="text-white text-14 flex flex-row mb-8 lg:mb-10">
            <div className="font-bold mr-4">{slide}</div>
            <div
              style={{
                width: 77,
                borderBottom: "1px solid #fff",
                marginBottom: "12px",
              }}
            >
              {" "}
            </div>
            <div className="ml-4">{slides}</div>
          </div>
        )}
        <div
          className={`
            text-white text-h2-small font-bold mb-8
          `}
        >
          {title}
        </div>

        <div className="text-black-600 text-p">{subtext}</div>
      </div>

      <style jsx global>{`
        //.product-slide__image-wrapper {
        //  min-height: 400px;
        //}
        .product-slide__image {
          width: 300px;
          max-width: 300px;
          height: 200px;
          max-height: 200px;
        }

        @media ${viewports.mdOrBigger} {
          .product-slide__image {
            width: 500px;
            max-width: 500px;
            height: 330px;
            max-height: 330px;
          }
        }

        // @media ${viewports.xlOrBigger} {
        //   .product-slide__image {
        //     width: 500px;
        //   }
        // }
      `}</style>
    </div>
  );
};

ProductSlide.propTypes = {
  title: string.isRequired,
  subtext: string.isRequired,
  imageName: string.isRequired,
  slideIndex: number,
  numberOfSlides: number,
};

ProductSlide.defaultProps = {
  slideIndex: null,
  numberOfSlides: null,
};

export default ProductSlide;
