import React, { useState } from "react";
import { string, bool, shape, arrayOf } from "prop-types";
import { motion } from "framer-motion";
import Carousel from "nuka-carousel";
import { isMobileOnly } from "react-device-detect";
import useStartMotion from "../../../hooks/use-start-motion";
import ProductSlide from "./product-slide";
import circle from "../../../images/svg/vector-08.svg";
import Heading from "../../Base/HeadingBuilder";

const settings = {
  // autoplay: true,
  heightMode: "max",
  slidesToScroll: 1,
  slidesToShow: 1,
  withoutControls: true,
  wrapAround: true,
  dragging: true,
};

const variants = {
  hidden: { opacity: 0, y: 150 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const ProductSlides = ({ className, title, slides, animations }) => {
  const { ref, controls } = useStartMotion();
  const [slideIndex, setSlideIndex] = useState(0);

  const slidesComponents = slides.map((slide, idx) => (
    <ProductSlide
      key={slide.title}
      {...slide}
      slideIndex={idx}
      numberOfSlides={slides.length}
    />
  ));

  return (
    <section
      data-cy="product-slides"
      className={`
        relative max-w-1440 w-full
        overflow-hidden
        mx-0 lg:mx-10
        px-4 md:px-10 lg:px-40
        py-20 lg:pt-24 lg:pb-40
        bg-black rounded-large
        ${className}
      `}
      ref={ref}
    >
      <motion.div
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.1, duration: 0.8 }}
      >
        <Heading
          level={2}
          injectionType={1}
          withBlueSymbol
          className="relative z-1 xl:max-w-70-percent text-white mb-16 xl:min-h-250 whitespace-pre-line"
        >
          {title}
        </Heading>
      </motion.div>

      <motion.div
        className=""
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <img
          className={`
        absolute z-0
        transform scale-75 md:scale-100
        -left-52 md:-left-24 lg:left-8
        top-48 lg:top-72
        `}
          src={circle}
          alt="product slide background"
        />
        <Carousel
          {...settings}
          // beforeSlide={(idx) =>
          //   setSlideIndex(idx + 1 >= slides.length ? 0 : idx + 1)
          // }
          slideIndex={slideIndex}
          afterSlide={(idx) => setSlideIndex(idx)}
        >
          {slidesComponents}
        </Carousel>

        <div className="relative z-1 flex flex-row mt-8 md:mt-16 lg:mt-8 lg:ml-32 xl:ml-60">
          {slides.map((slide, idx) => {
            return (
              <div
                key={slide.title}
                className={`
                border border-white text-white
                rounded-full w-8 h-8 cursor-pointer
                flex flex-row items-center justify-center
                `}
                style={{
                  border:
                    slideIndex === idx
                      ? "1px solid #fff"
                      : "1px solid transparent",
                }}
                onClick={() => setSlideIndex(idx)}
              >
                &bull;
              </div>
            );
          })}
        </div>
      </motion.div>
    </section>
  );
};

ProductSlides.propTypes = {
  className: string,
  title: string.isRequired,
  slides: arrayOf(
    shape({
      title: string,
      subtext: string,
      imageName: string,
    })
  ),
  animations: bool,
};

ProductSlides.defaultProps = {
  className: "",
  slides: [],
  animations: true,
};

export default ProductSlides;
